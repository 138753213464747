import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import { COD_RECAPTCHA_V3 } from '../../../enviroments/enviroment';
import { getCodigo, sendCode } from '../../../services/auth';
import { addLogs } from '../../../services/logs_proteccion';
import Timer from "../../helpers/Timer";
import VerificationInput from 'react-verification-input';

const AuthPasswordFormCodigo = ({ errorData, mensaje, formCode, recaptchaRef, setMensaje, setSpinner, form, setErrorData, setCount, setFormCode }) => {

    const [codigo, setCodigo] = useState([]);
    const [campos, setCampos] = useState(6);
    const [endTimerStatus, setEndTimerStatus] = useState(false);
    const [timeCode, setTimeCode] = useState(300000);
    const [reenvioAntesDeTiempo, setReenvioAntesDeTiempo] = useState(false);
    const [clickReenvioAntesDeTiempo, setClickReenvioAntesDeTiempo] = useState(false);

    const handleSubmitCodigo = async (e) => {
        e.preventDefault();

        let camposCodigo = document.querySelectorAll(".codigo-field");
        let codeOtp = parseInt(
            codigo[0] + codigo[1] + codigo[2] + codigo[3] + codigo[4] + codigo[5]
        );
        formCode.code = codeOtp;
        setFormCode(formCode);
        setMensaje("");

        if (isNaN(formCode.code)) {
            camposCodigo.forEach((campo) => {
                campo.style.borderColor = "var(--rojo)";
            });
            setMensaje("Debes proporcionar un código.");
            setErrorData(true);
            return;
        }

        if (formCode.code !== "") {

            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset()
            if (!token) {
                setMensaje('Captcha inválido, actualizar web')
                return
            }

            setSpinner(true)

            let objUsuario = {
                id: formCode.id,
                code: formCode.code,
                tokenCaptcha: token
            }

            addLogs('login', 'Intento Recuperar contraseña código', form.identificacion);

            sendCode(objUsuario).then(res => {

                // console.log({ res })
                setSpinner(false)
                if (res.codigo === 0) {

                    addLogs('login', 'Recuperar contraseña código Exitoso', form.identificacion);
                    setMensaje("")
                    setErrorData(false)
                    setCount(3)
                } else {
                    addLogs('login', 'Error Recuperar contraseña código', form.identificacion);
                    setMensaje('Código inválido')
                    setErrorData(true)
                }
            }).catch((err) => {
                setMensaje(err)
                setErrorData(true)
                setSpinner(false)
            })
        } else {
            setMensaje("Debes proporcionar un código.")
        }
    }

    const handleChangeInputs = (element, index) => {
        setMensaje("");
        let camposCodigo = document.querySelectorAll(".codigo-field");
        camposCodigo.forEach((campo) => {
            campo.style.borderColor = "#666666";
            campo.style.color = "var(--bs-offcanvas-color)";
        });
    
        if (isNaN(element.value)) return false;
    
        setCodigo([...codigo.map((d, idx) => (idx === index ? element.value : d))]);
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const resendCodigo = async () => {
        setSpinner(true)
        if (endTimerStatus) {
            setEndTimerStatus(false);
            setTimeCode(300000);
        } else {
            setReenvioAntesDeTiempo(true);
            setEndTimerStatus(true)
            setClickReenvioAntesDeTiempo(true);
        }
    
        let camposCodigo = document.querySelectorAll(".codigo-field");
        camposCodigo.forEach((campo) => {
          campo.style.borderColor = "#666666";
          campo.style.color = "var(--bs-offcanvas-color)";
        });
        setMensaje("");
    
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        if (!token) {
          setMensaje("Captcha inválido, actualizar web");
          return;
        }
    
        let objUsuario = {
          identificacion: form.identificacion,
          tokenCaptcha: token,
        };
    
        addLogs("login", "Reenvía código", `${objUsuario}`);
    
        getCodigo(objUsuario).then((res) => {
            // console.log({ res })
            setSpinner(false);
            setClickReenvioAntesDeTiempo(false);
            setTimeCode(300000);
            if (res.codigo === 0) {
                setErrorData(false);
                addLogs(
                    "Activación cuenta usuario",
                    "se envió SMS al usuario con el código de activación",
                    `${objUsuario}`
                );
                setFormCode({ ...formCode, id: res.data, tokenCaptcha: token });
    
                setCount(2);
                return;
            } else if (res.codigo === 1) {
                if (res.mensaje === "No se validó el captcha") {
                    setMensaje(res.mensaje);
                } else {
                    setMensaje("Correo no válido.");
                }
                } else {
                    setMensaje(res.mensaje);
                }
            setErrorData(true);
        })
        .catch((err) => {
            setMensaje(err);
            setErrorData(true);
            setSpinner(false)
        });
    };

    const handleComplete = (value) => {
        setCodigo(value);
    };

    const endTimer = () => {
        setEndTimerStatus(true);
    };
    
    const handleSetTimer = (t) => {
        setTimeCode(t);
    };

    useEffect(() => {
        if (reenvioAntesDeTiempo && endTimerStatus) {
            setReenvioAntesDeTiempo(false);
            setEndTimerStatus(false);
        }
    }, [reenvioAntesDeTiempo])

    return (
        <div className="auth__login">
            <h3 className="auth__title">Recibiste un código</h3>
            <p className="auth__subtitle" >Consulta tu bandeja de correo o SMS e ingresa el código de verificación que recibiste para recuperar la contraseña.</p>
            <form onSubmit={handleSubmitCodigo}>
                <div className="campos__codigo" style={{ paddingBottom: '1rem' }}>
                    <VerificationInput
                        name="code"
                        length={campos}
                        classNames={{
                        character: "codigo-field",
                        }}
                        validChars="0-9"
                        passwordMode={true}
                        onComplete={(value) => handleComplete(value)}
                        onChange={(value, index) => handleChangeInputs(value, index)}
                        placeholder=""
                    />
                    {mensaje && (
                        <div
                        className="texto__error"
                        style={{ textAlign: "center", paddingTop: "1rem" }}
                        >
                        <p>{mensaje}</p>
                        </div>
                    )}
                </div>

                <div className="timer__codigo">
                    {endTimerStatus && !clickReenvioAntesDeTiempo ? (
                    <p className="auth__subtitle">
                        El código expiró{" "}
                        <span className="reenviar__codigo" onClick={resendCodigo}>
                        Solicitar de nuevo
                        </span>
                    </p>
                    ) : (
                    <div>
                        <p className="auth__subtitle">
                            Tu código expira en{" "}
                            <span style={{ color: "var(--rojo)", display: "inline-block" }}>
                                <Timer
                                    mill={timeCode}
                                    end={endTimer}
                                    status={endTimerStatus}
                                    handleSetTimer={handleSetTimer}
                                />
                            </span>
                        </p>
                        <p className="auth__subtitle">
                            No recibí el código <span style={{ fontFamily: 'Scotia-Bold', cursor: 'pointer', textDecoration: 'underline' }} onClick={resendCodigo}>Reenviar código</span><br/>
                            o llámanos al <a className='llamar-telefono' href="tel:8008012402" style={{ fontFamily: 'Scotia-Bold', textDecoration: 'underline' }}>800 801 2402</a>
                        </p>
                    </div>
                    )}
                </div>

                <div>
                    <input
                        type="submit"
                        className="btn__login completo"
                        disabled={endTimerStatus}
                        value="Validar"
                    />

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={COD_RECAPTCHA_V3}
                    />
                </div>
            </form>
        </div>
    )
}

export default AuthPasswordFormCodigo