import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../../../context/auth/authContext";
import Modulos from "../../../general/screen/Modulos";
import { getDataUser, getToken } from "../../../helpers/validations";
import EcosistemaTitulo from "../../ecosistemas/EcosistemaTitulo";
import HomeInUser from "../../principal/components/HomeInUser";
import SaludCards from "../components/SaludCards";
import ModalEncuentaSaludBeneficiario from "../../../hooks/components/ModalEncuentaSaludBeneficiario";
import {
  TraerBeneficiarios,
  getCurp,
  getMedismart,
} from "../../../../services/salud";
import ModalSaludMedicaEnCasa from "../../../hooks/components/ModalSaludMedicaEnCasa";
import "../../styles/salud.css";
import SpinnerCuadrado from "../../../helpers/SpinnerCuadrado";
import useTitle from "../../../hooks/useTitle";
import Swal from "sweetalert2";
import { addLogs } from "../../../../services/logs_proteccion";

const SaludScreen = () => {
  useTitle({ title: "Detalle Ecosistema Salud" });
  const { pathname } = useLocation();
  const {
    globalActualizarEcosistemaActivo,
    globalActualizarBeneficiariosSalud,
    globalBeneficiariosSalud,
    globalBeneficiariosSaludModal,
    globalBeneficiariosSaludModalEnCasa,
    globalDataUser,
    globalActualizarBeneficiariosSaludModalEnCasa,
  } = useContext(AuthContext);
  const [mostrarModalEncuesta, setMostrarModalEncuesta] = useState(false);
  const [statusBeneficiarios, setStatusBeneficiarios] = useState(false);
  const [mostrarModalSaludEnCasa, setMostrarModalSaludEnCasa] = useState(false);
  const [usuarioTitular, setUsuarioTitular] = useState(false); // Si es un titular
  const [spinner, setSpinner] = useState(false);

  const idPlanesIndividuales = [
    "2960",
    "2961",
    "2962",
    "2963",
    "2964",
    "2970",
    "2971",
    "2972",
    "2973",
    "2974",
  ];

  const getBeneficiarios = async () => {
    setSpinner(true);
    let data = await TraerBeneficiarios(getToken());
    let tmpBeneficiario = statusBeneficiarios;
    let dataCurp = await getCurp(getToken());
    if (data?.codigo === 1) {
      let dataUser = await getDataUser();
      tmpBeneficiario = true;
      setStatusBeneficiarios(true);
      if (
        data.data["$values"][0].idUsuarioTitular.toString() ===
        atob(dataUser.idUsuario)
      ) {
        setUsuarioTitular(true);
      }
      setTimeout(() => {
        setMostrarModalEncuesta(false);
      }, 200);
    } else {
      //Verifica si el plan del usuario es individual
      if (idPlanesIndividuales.includes(globalDataUser.globalPlanCardifId)) {
        if (!dataCurp?.data) {
          setMostrarModalEncuesta(true);
        } else {
          setStatusBeneficiarios(true);
        }
      } else {
        setMostrarModalEncuesta(true);
      }
    }
    globalActualizarBeneficiariosSalud(tmpBeneficiario);
    setSpinner(false);
  };

  const getModalBeneficiarios = () => {
    if (globalBeneficiariosSaludModal > 0) {
      setMostrarModalEncuesta(true);
    }
  };

  const getModalBeneficiariosEnCasa = () => {
    if (globalBeneficiariosSaludModalEnCasa > 0 && usuarioTitular) {
      setMostrarModalSaludEnCasa(true);
    } /*else {
      if (globalBeneficiariosSaludModalEnCasa > 0) {
        saltoMedismart(0);
      }
    }*/
  };

  const saltoMedismart = async (idBeneficiario, nombreBeneficiario) => {
    Swal.fire({
      icon: "info",
      title: "Cargando...",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  
    try {
      const data = await getMedismart(getToken(), idBeneficiario, "salud");
  
      if (data && data.codigo === 1) {
        Swal.fire({
          icon: "info",
          title: "Medismart",
          text: data.mensaje || "Información adicional no disponible.",
        });
      } else if (data && typeof data === "string") {
        window.open(data, "_blank");
        globalActualizarBeneficiariosSaludModalEnCasa(
          globalBeneficiariosSaludModalEnCasa - 1
        );
        Swal.close();
        addLogs(
          "Medicina online",
          "Salto exitoso a Medismart",
          "Usuario del servicio: " + nombreBeneficiario
        );
      } else {
        // Manejo de otros casos inesperados
        throw new Error(data?.mensaje || "Error desconocido en salto a Medismart.");
      }
    } catch (error) {
      // Manejar errores en la llamada
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "Error en salto a Medismart",
      });
    }
  };

  useEffect(() => {
    getBeneficiarios();
    if (pathname === "/ecosistema/salud") {
      globalActualizarEcosistemaActivo(12);
    }
  }, [globalBeneficiariosSalud]);

  useEffect(() => {
    getModalBeneficiarios();
  }, [globalBeneficiariosSaludModal]);

  useEffect(() => {
    getModalBeneficiariosEnCasa();
  }, [globalBeneficiariosSaludModalEnCasa]);

  return (
    <div className="homein">
      {spinner && <SpinnerCuadrado />}
      <EcosistemaTitulo />

      <div className="homein__resumen">
        <HomeInUser
          id={12}
          statusBeneficiarios={statusBeneficiarios}
          setMostrarModalEncuesta={setMostrarModalEncuesta}
        />
      </div>

      <div className="homein__detalle">
        <Modulos />
        <SaludCards />
      </div>

      <ModalEncuentaSaludBeneficiario
        show={mostrarModalEncuesta}
        close={setMostrarModalEncuesta}
      />

      <ModalSaludMedicaEnCasa
        show={mostrarModalSaludEnCasa}
        close={setMostrarModalSaludEnCasa}
        saltoMedismart={saltoMedismart}
      />
    </div>
  );
};

export default SaludScreen;
