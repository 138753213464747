import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../encuestaCoursera.css';
import { TraerBeneficiarios, getCurp } from '../../../services/salud';
import { getDataUser, getToken, isactive } from '../../helpers/validations';
import AuthContext from '../../../context/auth/authContext';

const ModalSaludMedicaEnCasa = (props) => {
  const { globalActualizarBeneficiariosSalud } = useContext(AuthContext);
  const [infoCuestionario, setInfoCuestionario] = useState(defaulBeneficiario());
  const [statusCurp, setStatusCurp] = useState(false);
  const [curpPrincipal, setCurpPrincipal] = useState('');
  const [nombrePrincipal, setNombreCurpPrincipal] = useState('');
  const [beneficiarios, setBeneficiarios] = useState([]);

  function defaulBeneficiario() {
    return {
      curp: '',
      permitirSiempre: false,
    };
  }

  const handleClose = () => {
    props.close(false);
  };

  const getCurpModal = async () => {
    if (statusCurp === false) {
      let data = await getCurp(getToken());
      if (data?.codigo === 1) {
        setStatusCurp(true);
        setCurpPrincipal(data?.data);
      }
    }
  };

  const getBeneficiarios = async () => {
    let data = await TraerBeneficiarios(getToken());
    if (data?.codigo === 1) {
      setBeneficiarios(data.data['$values']);
    }
  };

  const handleSaltar = async (e) => {
    e.preventDefault();
    props.saltoMedismart(infoCuestionario.curp);
    handleClose();
  };

  const onChangeForm = (e, i) => {
    let value = e.target.value;
    const key = e.target.name;
    let tmpForm = { ...infoCuestionario };
    if (key.indexOf('permitirSiempre') > -1) {
      value = document.querySelector('input[name="permitirSiempre"]:checked');
    }
    tmpForm[key] = value;
    setInfoCuestionario(tmpForm);
  };

  useEffect(() => {
    getCurpModal();
    getBeneficiarios();
    if (isactive()) {
      let dataUser = getDataUser();
      setNombreCurpPrincipal(dataUser.nombres);
    }
  }, [globalActualizarBeneficiariosSalud]);

  return (
    <>
      <Modal show={props.show} onHide={handleClose} size="lg" backdrop="static" centered>
        <Modal.Body>
          <div className="encuesta__coursera mt-5">
            <h1 className="mb-5">¿Quién estará recibiendo el servicio el día de hoy?</h1>
            <form className="mt-4 mb-4" onSubmit={handleSaltar}>
              <div className="col-12 d-flex text-center justify-content-center">
                <div className="col-6">
                  <select
                    className="form-control-salud-beneficiario mt-1 mb-4"
                    name="curp"
                    onChange={onChangeForm}
                    required
                  >
                    <option value="">Seleccionar</option>
                    {beneficiarios && statusCurp && (
                      <>
                        <option value="0">Titular - {nombrePrincipal}</option>
                        {beneficiarios.map((b, i) => {
                          return (
                            <option key={i} value={b.idContingenciaPrimaria}>
                              {b.parentesco} - {b.nombreUsuario}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </select>
                </div>
              </div>
              <div className="card__btns__encuesta">
                <button
                  className={`activacuenta__btn azul-salud`}
                  style={{ borderRadius: '7px', fontSize: '12px' }}
                  type="submit"
                >
                  CONTINUAR
                </button>
                <button
                  className={`unirme__btn azul-salud-volver`}
                  style={{ borderRadius: '7px', fontSize: '12px' }}
                  type="button"
                  onClick={handleClose}
                >
                  VOLVER
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalSaludMedicaEnCasa;
